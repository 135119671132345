.background-image {
  background-image: url('../src/assests/images/laptops-banner.jpg'); /* Update with your image path */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  height: 100vh; /* Full height */
}

.text-overlay {
  color: white; /* Text color */
  text-align: center; /* Center the text */
  z-index: 1; /* Make sure text is on top */
}

.text-overlay h1 {
  font-size: 30px; /* Adjust as needed */
}

.text-overlay p {
  font-size: 20px; /* Adjust as needed */
}
.left_button{
  margin-right: 20px;
}
.services-section {
  background-color: #f8f9fa; /* Light background similar to the image */
  padding: 10px;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333; /* Darker heading */
  text-align: center;
}

.section-description {
  color: #666;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.service-card {
  border: none;  /* Remove card border */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border-radius: 10px; /* Rounded corners similar to the image */
  display: flex;
  flex-direction: column;
  height: 100%;  /* Ensure all cards have the same height */
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #ff0055;
  color: #fff;
  border-radius: 50%;
  margin: 0 auto;
}

.service-card .card-title {
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.service-card .card-text {
  color: #666;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.5rem;
  }

  .section-description {
    font-size: 0.9rem;
  }
}
.why-us-section {
  background-color: #f8f9fa; /* Light background */
  padding: 2rem 0;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.section-subtitle {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
}

.accordion-button {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-body {
  color: #666;
  font-size: 1rem;
}

.number {
  color: #ff0055; /* Matching the color for numbers */
  font-weight: bold;
  margin-right: 0.5rem;
}

.why-us-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a soft shadow */
}

@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 1.5rem;
  }
}
.step-number {
  font-size: 48px;
  color: #d3d3d3;
  font-weight: bold;
}

.step-title {
  font-weight: 600;
  margin: 15px 0 10px;
}

.step-description {
  font-size: 14px;
  color: #666;
}
.header_btn{
  padding: 5px 10px;
  border-radius: 10px;
  border-radius: 1px solid #fff;
  text-decoration: none;
  color: #fff;
}